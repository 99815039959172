import { Component, OnInit } from '@angular/core';
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  public message: string
  public type: string

  constructor(private popupService: PopupService) {
    this.popupService.message.subscribe(
      message => this.message = message
    );
    this.popupService.type.subscribe(
      type => this.type = type
    );
  }

  ngOnInit(): void {
  }

}
