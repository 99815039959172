import { PlanoService } from './../../services/plano.service';
import { Component, OnInit } from "@angular/core";
import { PopupService } from '../../services/popup.service';

@Component({
  selector: "app-rede-credenciada",
  templateUrl: "./rede-credenciada.component.html",
  styleUrls: ["./rede-credenciada.component.css"],
})
export class RedeCredenciadaComponent implements OnInit {

  especialidades: {especialidade: string}[];
  ufs: {uf: string}[];
  cidades: {cidade: string}[];
  bairros: {bairro: string}[];

  reqRede: boolean;

  especialidadethis: string;
  uf: string;
  cidade: string;
  bairro: string;

  redecredenciada: any[];
  originalRede: any[];

  quantity = 18;
  offset = 0;
  limit = this.quantity;

  constructor(
    private planoService: PlanoService,
    private popupService: PopupService
  ) {  }

  ngOnInit(): void {
    this.getEspecialidades();
  }

  getEspecialidades() {
    this.ufs = null;
    this.cidades = null;
    this.bairros = null;
    this.redecredenciada = null;
    this.planoService.getEspecialidades().subscribe(
      res => {
        this.especialidades = res;
      }
    );
  }

  getUfs(especialidade: string) {
    this.cidades = null;
    this.bairros = null;
    this.redecredenciada = null;
    this.especialidadethis = especialidade;
    this.planoService.getUfsByEspecialidade(especialidade).subscribe(
      res => {
        this.ufs = res;
      }
    );
  }

  getCidades(uf: string) {
    this.uf = uf;
    this.bairros = null;
    this.redecredenciada = null;
    this.planoService.getCidadesByUf(uf).subscribe(
      res => {
        this.cidades = res;
      }
    );
  }

  getBairros(cidade: string) {
    this.cidade = cidade;
    this.redecredenciada = null;
    this.planoService.getBairros(cidade).subscribe(
      res => {
        this.bairros = res;
      }
    );
  }

  getRedeCredenciada(bairro: string) {
    this.quantity = 18;
    this.offset = 0;

    this.planoService.getRedeCredenciada(this.especialidadethis, this.uf, this.cidade, bairro).subscribe(
      res => {
        this.redecredenciada = res;
        if(!res.length) {
          this.reqRede = true;
        } else {
          this.reqRede = false;
        }
      },
      error => {
        this.popupService.show('Erro na busca pela rede credenciada!', 'danger', 3000);
      }
    )
  }

  pagination(orientation: number) {
    window.scrollTo({ top: 470, behavior: "smooth" });
    if (orientation < 0) {
      this.offset -= this.quantity;
      this.limit -= this.quantity;
    } else {
      this.offset += this.quantity;
      this.limit += this.quantity;
    }
  }
}
