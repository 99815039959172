import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import variaveis from '../urls/torra-url';
import { IbodyNewPlanoBeneficiario } from '../model/beneficiario.model';

@Injectable({
  providedIn: 'root'
})
export class PlanoService {

  plano: BehaviorSubject<any>;

  beneficiario: BehaviorSubject<any>;

  constructor(
    private http: HttpClient
  ) {
    this.plano = new BehaviorSubject<any>(undefined);
    this.beneficiario = new BehaviorSubject<any>(undefined);
  }

  getBeneficiario() {
    return this.http.get<any>(variaveis.getBeneficiario);
  }

  cadastraPlanoDependentes(form: IbodyNewPlanoBeneficiario) {
    return this.http.post(variaveis.createPlanoDependente, form);
  }

  getEspecialidades() {
    return this.http.get<any>(variaveis.getEspecialidades);
  }
  getUfsByEspecialidade(especialidade: string) {
    return this.http.get<any>(variaveis.getUfs(btoa(especialidade)));
  }

  getCidadesByUf(uf: string) {
    return this.http.get<any>(variaveis.getCidades(btoa(uf)))
  }

  getBairros(cidade: string) {
    return this.http.get<any>(variaveis.getBairros(btoa(cidade)));
  }
  getRedeCredenciada(especialidade: string, uf: string, cidade: string, bairro: string) {
    return this.http.get<any>(variaveis.getRedeCredenciada(btoa(especialidade), btoa(uf), btoa(cidade), btoa(bairro)))
  }
}
