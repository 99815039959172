<div class="container-fluid justify-content-center header">
  <div class="row">
    <div class="col-sm-12" style="background-color: #1e1f47">
      <div class="row">
        <div class="col-sm-3 col-4 p-2">
          <a [routerLink]="['/']" routerLinkActive="router-link-active"
            ><img src="./assets/logo/torra.png" class="logo"
          /></a>
        </div>

        <div class="col-sm-9 col-8 bg-light logo-cnu">
          <img
            *ngIf="logo"
            [src]="'./assets/logo/' + logo + '.png'"
            class="logOp"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-fluid text-center alert alert-danger mb-0"
    *ngIf="isIEOrEdge"
  >
    O internet explorer não suporta as funcionalidades desse site. Por favor,
    utilizar outro navegador.
  </div>
</div>
