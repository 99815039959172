<ng-container>
  <div class="container">
    <div class="desk">
      <div class="w-25 titleFale text-center">
        <h5>
          <p
            class="font-weigth-bold text-light"
            style="background-color: var(--primary-color)"
          >
            Fale conosco
          </p>
        </h5>
      </div>
      <div class="text-center my-5">
        <img
          src="./assets/banner/paginas/faleConosco.png"
          alt="Fale conosco"
          class="w-100"
        />
      </div>
    </div>
    <div class="mobile">
      <div class="titleFale text-center">
        <h5>
          <p
            class="font-weigth-bold text-light"
            style="background-color: var(--primary-color)"
          >
            Fale conosco
          </p>
        </h5>
      </div>
      <div class="text-center my-5">
        <img
          src="./assets/banner/paginas/mobile/faleConosco.png"
          alt="Fale conosco"
          class="w-100"
        />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="row title" style="margin-top: 10px">
            Tem alguma dúvida?
          </div>
          <div class="row dados" style="margin-top: 10px">
            Entre em contato conosco!
          </div>
          <div class="row dados" style="margin-top: 10px">
            Atendemos em horário comercial através dos seguintes canais:
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="row" style="margin-top: 20px">
            <div class="col-3 text-center">
              <img src="./assets/icon/contato/whats.png" alt="Whatsapp" />
            </div>
            <div class="col-9">(11) 95333-8000</div>
          </div>
          <div class="row" style="margin-top: 20px">
            <div class="col-3 text-center">
              <img src="./assets/icon/contato/telefone.png" alt="Telefone" />
            </div>
            <div class="col-9">(11) 3357-8000</div>
          </div>

          <div class="row" style="margin-top: 20px">
            <div class="col-3 text-center">
              <img src="./assets/icon/contato/email.png" alt="E-mail" />
            </div>
            <div class="col-9">duvida@celebrecorretora.com.br</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
