import { Component, OnInit } from "@angular/core";
import { PlanoService } from "../../services/plano.service";
import { Router } from "@angular/router";
import {
  IbodyDependente,
  IbeneficiarioRequest,
} from "../../model/beneficiario.model";

@Component({
  selector: "app-adesao",
  templateUrl: "./adesao.component.html",
  styleUrls: ["./adesao.component.css"],
})
export class AdesaoComponent implements OnInit {
  beneficiario: IbeneficiarioRequest;

  constructor(private planoService: PlanoService, private router: Router) {}

  ngOnInit(): void {
    this.getBeneficiario();
  }

  getBeneficiario() {
    this.planoService.getBeneficiario().subscribe((res) => {
      if (!res.plano) {
        this.router.navigate(["/"]);
        return;
      }
      this.beneficiario = res;
    });
  }
}
