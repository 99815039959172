import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarteirinhaComponent } from './components/carteirinha/carteirinha.component';
import { EtapasProjetoComponent } from './components/etapas-projeto/etapas-projeto.component';
import { FaleConoscoComponent } from './components/fale-conosco/fale-conosco.component';
import { PerguntasComponent } from './components/perguntas/perguntas.component';
import { PassoComponent } from './components/passo/passo.component';
import { DocumentosComponent } from './components/documentos/documentos.component';
import { BeneficiosComponent } from './components/beneficios/beneficios.component';
import { RedeCredenciadaComponent } from './components/rede-credenciada/rede-credenciada.component';
import { AplicativoComponent } from './components/aplicativo/aplicativo.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './interceptors/auth.guard';
import { CarouselComponent } from './components/carousel/carousel.component';
import { EscolhaComponent } from './components/escolha/escolha.component';
import { EscolherplanoComponent } from './components/escolherplano/escolherplano.component';
import { AdesaoComponent } from './components/adesao/adesao.component';


const routes: Routes = [
  {path: '', component: CarouselComponent},
  {path: 'carteirinha', component: CarteirinhaComponent, canActivate: [AuthGuard]},
  {path: 'adesao', component: AdesaoComponent, canActivate: [AuthGuard]},
  {path: 'etapas', component: EtapasProjetoComponent, canActivate: [AuthGuard]},
  {path: 'faleConosco', component: FaleConoscoComponent, canActivate: [AuthGuard]},
  {path: 'perguntas', component: PerguntasComponent, canActivate: [AuthGuard]},
  {path: 'passo', component: PassoComponent, canActivate: [AuthGuard]},
  {path: 'documentos', component: DocumentosComponent, canActivate: [AuthGuard]},
  /* DropDown Routes */
  {path: 'beneficio', component: BeneficiosComponent, canActivate: [AuthGuard]},
  {path: 'rede', component: RedeCredenciadaComponent, canActivate: [AuthGuard]},
  {path: 'aplicativo', component: AplicativoComponent, canActivate: [AuthGuard]},
  {path: 'escolhaplano', component: EscolherplanoComponent, canActivate: [AuthGuard]},
  {path: 'escolha', component: EscolhaComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
