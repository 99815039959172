const variaveis = {
  getBeneficiario: '/torradental/beneficiario',
  createPlanoDependente: '/torradental/escolhaplano',
  getEspecialidades: '/torradental/rede/especialidades',
  getUfs: (especialidade: string) => {return `/torradental/rede/ufs/${especialidade}`},
  getCidades: (uf: string) => {return `/torradental/rede/cidades/${uf}`},
  getBairros: (cidade: string) => { return `/torradental/rede/bairros/${cidade}`},
  getRedeCredenciada: (especialidade: string, uf: string, cidade: string, bairro: string ) => {return `/torradental/rede/${especialidade}/${uf}/${cidade}/${bairro}`}
};

export default variaveis;
