<div
  class="w-100 text-center position-fixed popup alert show"
  [ngClass]="{
    'alert-success': type == 'success',
    'alert-danger': type == 'danger'
  }"
  style="z-index: 9999; top: 0"
>
  <strong *ngIf="type == 'success'">Sucesso!</strong>
  <strong *ngIf="type == 'danger'">Erro!</strong> {{ message }}
</div>
