import { Component, OnInit } from '@angular/core';
import { BeneficioImageNode } from '../../model/current.model';

@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.css'],
})
export class BeneficiosComponent implements OnInit {
  beneficios: BeneficioImageNode[] = [
    {
      imagePath: './assets/banner/paginas/beneficio/farmacia.png',
      titulo: 'DescontoFarmácia',
      texto:
        'Decontos de até 75% em medicamentos nas farmácias Drogaria São Paulo e Drogarias Pacheco',
    },
    {
      imagePath: './assets/banner/paginas/beneficio/app.png',
      titulo: 'Aplicativo Amil Clientes',
      texto:
        'O aplicativo Amil Cliente foi criado para deixar a Amil Empresarial ainda mais próxima de você.',
    },
    {
      imagePath: './assets/banner/paginas/beneficio/atendimento.png',
      titulo: 'Atendimento 24h',
      texto:
        'Amil Teleatendimento 24 horas por dia, 7 dias por semana e 365 dias por ano para para os clientes Amil Empresarial.',
    },
  ];

  amilEmNumeros: BeneficioImageNode[] = [
    {
      imagePath: './assets/banner/paginas/beneficio/hospital.png',
      titulo: '1.773',
      texto: 'Hospitais',
    },
    {
      imagePath: './assets/banner/paginas/beneficio/laboratorio.png',
      titulo: '+5.9 mil',
      texto: 'Laboratórios Credenciados',
    },
    {
      imagePath: './assets/banner/paginas/beneficio/consultorio.png',
      titulo: '+20 mil',
      texto: 'Consultórios e Clínicas credenciadas',
    },
    {
      imagePath: './assets/banner/paginas/beneficio/empresas.png',
      titulo: '+367 mil',
      texto: 'Empresas Clientes',
    },
    {
      imagePath: './assets/banner/paginas/beneficio/internacoes.png',
      titulo: '533.406',
      texto: 'Internações por ano',
    },
    {
      imagePath: './assets/banner/paginas/beneficio/clientes.png',
      titulo: '+6 milhões',
      texto: 'Clientes',
    },
    {
      imagePath: './assets/banner/paginas/beneficio/consultas.png',
      titulo: '+24.7 M',
      texto: 'Hospitais',
    },
    {
      imagePath: './assets/banner/paginas/beneficio/exames.png',
      titulo: '+68.7 M',
      texto: 'Exames por ano',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
