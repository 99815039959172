<div class="container-fluid mt-5">
  <div class="row justify-content-center">
    <div class="col-11">
      <img
        style="width: 100%"
        src="./assets/banner/paginas/escolha.png"
        alt="Error"
      />
      <div class="texto">
        <h5 class="bg-default">Faça sua</h5>
        <h5 class="bg-blue">Escolha</h5>
      </div>
    </div>
  </div>

  <div class="row justify-content-center mt-5">
    <div class="col-10">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Coberturas</th>
            <th scope="col" class="text-center">Dental 200 DOC</th>
            <th scope="col" class="text-center">Dental E300</th>
            <th scope="col" class="text-center">Dental E700</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Plano:</th>
            <td class="text-center">
              <button
                type="button"
                class="btn btn-dark text-center"
                (click)="
                  redirectEscolhaPlano({
                    nome_plano: 'Dental 200DOC',
                    valor: 9.39
                  })
                "
              >
                Dental 200 Doc
              </button>
            </td>
            <td class="text-center">
              <button
                type="button"
                class="btn btn-dark text-center"
                (click)="
                  redirectEscolhaPlano({
                    nome_plano: 'Dental E300',
                    valor: 54.16
                  })
                "
              >
                Dental E300
              </button>
            </td>
            <td class="text-center">
              <button
                type="button"
                class="btn btn-dark text-center"
                (click)="
                  redirectEscolhaPlano({
                    nome_plano: 'Dental E700',
                    valor: 62.8
                  })
                "
              >
                Dental E700
              </button>
            </td>
          </tr>
          <ng-container *ngFor="let cobertura of atendimento">
            <tr>
              <th scope="row">{{ cobertura.cobertura }}</th>
              <td class="text-center">{{ cobertura.dental200 ? "X" : "" }}</td>
              <td class="text-center">{{ cobertura.dental300 ? "X" : "" }}</td>
              <td class="text-center">{{ cobertura.dental700 ? "X" : "" }}</td>
            </tr>
          </ng-container>
          <tr class="table-warning">
            <th scope="row">Valor/Mensalidade</th>
            <td class="text-center">R$ 9,39</td>
            <td class="text-center">R$ 54,16</td>
            <td class="text-center">R$ 62,80</td>
          </tr>

          <tr>
            <th scope="row">Plano:</th>
            <td class="text-center">
              <button
                type="button"
                class="btn btn-dark text-center"
                (click)="
                  redirectEscolhaPlano({
                    nome_plano: 'Dental 200DOC',
                    valor: 9.39
                  })
                "
              >
                Dental 200 Doc
              </button>
            </td>
            <td class="text-center">
              <button
                type="button"
                class="btn btn-dark text-center"
                (click)="
                  redirectEscolhaPlano({
                    nome_plano: 'Dental E300',
                    valor: 54.16
                  })
                "
              >
                Dental E300
              </button>
            </td>
            <td class="text-center">
              <button
                type="button"
                class="btn btn-dark text-center"
                (click)="
                  redirectEscolhaPlano({
                    nome_plano: 'Dental E700',
                    valor: 62.8
                  })
                "
              >
                Dental E700
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
