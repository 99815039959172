<div class="container p-1 mt-4 w-100">
  <div class="desk">
    <div class="w-25 title text-center">
      <h5>
        <p class="font-weigth-bold">Veja o que</p>
      </h5>
      <h5>
        <p class="bg-white font-italic">mudou!</p>
      </h5>
    </div>
    <div class="my-5">
      <img src="./assets/banner/paginas/app.png" class="w-100" />
    </div>
  </div>
  <div class="mobile">
    <div class="title text-center">
      <h5>
        <p class="font-weigth-bold">Veja o que</p>
      </h5>
      <h5>
        <p class="bg-white font-italic">mudou!</p>
      </h5>
    </div>
    <div class="">
      <img src="./assets/banner/paginas/mobile/app.png" class="w-100" />
    </div>
  </div>
  <br />
  <div class="jumbotron m-auto align-content-center">
    <!-- <div class="row">
            <div class="col-4 text-center" style="margin-top: 20px; height: 251px;">
                <div class="card" style="height: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Seu plano será...</h5>
                        <ng-container *ngIf="(plano === 'AMIL S750'); else one">
                            <h6 class="card-subtitle mb-2 text-muted"> </h6>
                            <p class="card-text">Com acomodação em Apartamento, é um plano com cobertura Nacional. Para saber mais sobre sua rede credenciada, clique no botão abaixo.</p>
                        </ng-container>

                        <ng-template #one>
                            <h6 class="card-subtitle mb-2 text-muted">
                                AMIL ONE 6.500 R1
                            </h6>
                            <p class="card-text">Com acomodação em Apartamento, é um plano com cobertura Nacional. Para saber mais sobre sua rede credenciada, clique no botão abaixo.</p>
                        </ng-template>
                        <a routerLink="../rede" class="btn stretched-link text-light" style="background-color: #FE5002;">Saiba
                  mais!</a>
                    </div>
                </div>
            </div> -->

    <!-- <div class="col-8" style="margin-top: 15px; height: 100%">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <img src="./assets/iconCarteira.png" class="col-2 carteira mb-2">
                            <h5 class="card-title col-10">Carteirinhas</h5>
                        </div>
                        <h6 class="card-subtitle mb-2 text-muted text-center">As numerações estarão disponíveis a partir do dia 02 de junho. Qualquer dúvida, por favor, entre em contato com a Célebre Corretora através do
                            <br><br><mark>e-mail: duvida@celebrecorretora.com.br</mark>.<br></h6>
                        <div class="overflow-auto">
                            <label class="font-weight-bold">Elegibilidade:<a style="color: #FE5002;"> Titular</a></label><br>
                            <label class="font-weight-bold">Nome:<a> </a></label><br>
                            <label class="font-weight-bold">Carteirinha:<a></a></label><br>
                        </div>
                    </div>
                </div>
            </div>
        </div><br> -->
    <div class="card">
      <div class="card-body">
        <p style="color: #fe5002" class="font-italic text-center">
          Carteirinha
        </p>
        <!-- <p class="font-weight-bold text-center">
          Aguarde, logo a numeração das carteirinhas estarão disponíveis aqui!
        </p> -->
        <p class="list-goup-item" *ngIf="beneficiario.carteirinha">
          Titular:
          <b>{{ beneficiario.nome }}</b
          ><br />
          Número da Carteirinha:
          <b>{{ beneficiario.carteirinha }}</b
          ><br />
          <ng-container *ngFor="let dependente of beneficiario.dependentes">
            Dependente:
            <b>{{ dependente.nome }}</b
            ><br />
            Número da Carteirinha:
            <b>{{ dependente.carteirinha }}</b>
          </ng-container>
        </p>
        <ng-container *ngFor="let beneficiario of beneficiarios">
          <div>
            Nome: {{ beneficiario.nome }} <br />
            Carteirinha: {{ beneficiario.carteirinha }}
          </div>
        </ng-container>
      </div>
    </div>
    <br />
    <!-- <div class="container p-2 roundend" style="background-color: white;">
            <div class="row ml-2 my-3">
                <img src="./assets/iconDin.png">
                <div class="text-dark font-weight-bold ml-2">Coparticipação</div>
            </div>
            <div>A Coparticipação é um valor cobrado do beneficiário pela operadora, com base em suas utilizações. Esse valor é utilizado para garantir o equilíbrio do contrato e um valor de mensalidade menor. Você só paga a coparticipação quando utiliza seu
                plano de saúde. Para saber mais clique no botão abaixo.</div>

            <div class="text-center" style="margin-top: 20px;">
                <button routerLink="../coparticipacao" class="btn text-right text-light" style="background-color: #FE5002;">Saiba
                    mais</button>
            </div>
        </div> -->
  </div>
</div>
