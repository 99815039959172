import { Component, OnInit } from '@angular/core';
import { PlanoService } from '../../services/plano.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-escolha',
  templateUrl: './escolha.component.html',
  styleUrls: ['./escolha.component.css']
})
export class EscolhaComponent {

  constructor(
    private planoService: PlanoService,
    private router: Router
  ) {}

  atendimento = [
    {
      cobertura: 'Urgência e emergência',
      dental200: true,
      dental300: true,
      dental700: true
    },
    {
      cobertura: 'Consultas (inclusive aos sábados)',
      dental200: true,
      dental300: true,
      dental700: true
    },
    {
      cobertura: 'Prevenção (limpeza, profilaxia e aplicação de fluor)',
      dental200: true,
      dental300: true,
      dental700: true
    },
    {
      cobertura: 'Raio-x (panorâmico e periapical)',
      dental200: true,
      dental300: true,
      dental700: true
    },
    {
      cobertura: 'Periodontia (tratamento de gengiva)',
      dental200: true,
      dental300: true,
      dental700: true
    },
    {
      cobertura: 'Odontopedriatria (tratamento para criança)',
      dental200: true,
      dental300: true,
      dental700: true
    },
    {
      cobertura: 'Endodontia (tratamento de canal)',
      dental200: true,
      dental300: true,
      dental700: true
    },
    {
      cobertura: 'Dentística (restaurações)',
      dental200: true,
      dental300: true,
      dental700: true
    },
    {
      cobertura: 'Cirurgias e extrações (incluindo dente do siso)',
      dental200: true,
      dental300: true,
      dental700: true
    },
    {
      cobertura: 'Próteses e coroas (Conforme RN 211 da ANS)',
      dental200: true,
      dental300: true,
      dental700: true
    },
    {
      cobertura: 'Documentação Ortodôntica Básica',
      dental200: false,
      dental300: true,
      dental700: true
    },
    {
      cobertura: 'Ortodontia (instalação, manutenção e documentação ortodôntica)',
      dental200: false,
      dental300: true,
      dental700: true
    },
    {
      cobertura: 'Itens de Prótese em Resina',
      dental200: false,
      dental300: false,
      dental700: true
    },
    {
      cobertura: 'Prótese em resina (fixa, parcial, removível ou total)',
      dental200: false,
      dental300: false,
      dental700: true
    },
    {
      cobertura: 'Prótese em porcelana (fixa, parcial, removível ou total)',
      dental200: false,
      dental300: false,
      dental700: true
    },
    {
      cobertura: 'Clareamento estético caseiro',
      dental200: false,
      dental300: false,
      dental700: true
    },
    {
      cobertura: 'Atendimento nacional',
      dental200: true,
      dental300: true,
      dental700: true
    },
    {
      cobertura: 'Reembolso',
      dental200: true,
      dental300: true,
      dental700: true
    },

  ];

  redirectEscolhaPlano(objc: {nome_plano: string, valor: number}) {
    this.planoService.plano.next(objc);
    this.router.navigate(['/adesao']);

  }

}
