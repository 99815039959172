<div class="container text-center">
  <div class="row text-center" style="margin-top: 20px">
    <div class="col-12">
      <h4
        style="
          border: 0.1px solid var(--primary-color);
          border-top: 0;
          border-right: 0;
          border-radius: 0 0 0 10px;
          width: 30%;
          margin: 10px auto 20px auto;
          padding: 2px;
          box-shadow: -2px 2px 5px 0.5px var(--primary-color);
        "
      >
        Login
      </h4>
    </div>
  </div>
  <div class="row" style="margin-bottom: 20px">
    <div class="col-12 text-center">
      Para acessar o conteúdo desse site, é necessário que você informe o CPF do
      titular.
    </div>
  </div>
  <div class="row">
    <form [formGroup]="form" class="col-12" (ngSubmit)="onSubmit()">
      <div
        class="input-group form-group"
        style="width: 60%; margin: 20px auto 20px auto"
      >
        <input
          type="text"
          formControlName="cpf"
          class="text-center form-control"
          (keyup)="mCPF('login')"
          maxlength="14"
          placeholder="CPF TITULAR"
          [ngClass]="{
            'is-invalid':
              fLogin.cpf.errors && (fLogin.cpf.dirty || fLogin.cpf.touched)
          }"
          style="margin: 0 auto"
        />
        {{ permissao }}
      </div>
      <div>
        <button
          class="btn"
          style="
            margin-bottom: 20px;
            background-color: var(--primary-color);
            color: white;
          "
        >
          <span *ngIf="!loadingLogin" class="font-weight-bold">Acessar</span>
          <span
            *ngIf="loadingLogin"
            class="spinner-border spinner-border-sm text-light"
          ></span>
        </button>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          Caso tenha algum problema de acesso, por favor, informe a Célebre
          Corretora através do
          <strong>e-mail: <mark>duvida@celebrecorretora.com.br</mark></strong>
          ou através do WhatsApp
          <strong><mark>(11) 95333-8000</mark></strong> ou telefone
          <strong><mark>(11) 3357-8000</mark></strong
          >.
        </div>
      </div>
    </form>
  </div>
</div>
