import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentUser = this.authenticationService.currentUserValue;

    //Se não null
    if (currentUser.auth) {
      // Dá autorização
      return true;
    }

    //Não está logado (currentUser == null)
    //Redireciona para a página de login enviando como parametro a url atual
    this.router.navigate(["./login"], {
      queryParams: { returnUrl: state.url },
    });
    //Não dá autorização
    return false;
  }
}
