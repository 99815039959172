<div class="contaier">
  <div class="row img">
    <div class="col-12 col-sm-10 col-md-6">
      <img src="./assets/aplicativo/app1.jpg" alt="" class="w-100" />
    </div>
  </div>
  <div class="row img">
    <div class="col-12 col-sm-10 col-md-6">
      <img src="./assets/aplicativo/app2.png" alt="" class="w-100" />
    </div>
  </div>
  <div class="row img">
    <div class="col-12 col-sm-10 col-md-6">
      <img src="./assets/aplicativo/app3.png" alt="" class="w-100" />
    </div>
  </div>
  <div class="row img">
    <div class="col-12 col-sm-10 col-md-6">
      <img src="./assets/aplicativo/app4.png" alt="" class="w-100" />
    </div>
  </div>
</div>
