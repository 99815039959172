<ng-container>
  <div class="container">
    <div class="desk">
      <div class="w-25 title text-center">
        <h5>
          <p>Etapas do projeto!</p>
        </h5>
      </div>
      <div class="my-5">
        <img
          src="./assets/banner/paginas/etapas.png"
          alt="Etapas"
          style="width: 100%; margin: 0; position: relative"
        />
      </div>
    </div>
    <div class="mobile">
      <div class="title text-center">
        <h5>
          <p>Etapas do projeto!</p>
        </h5>
      </div>
      <div class="">
        <img
          src="./assets/banner/paginas/mobile/etapas.png"
          alt="Etapas"
          style="width: 100%; margin: 0; position: relative"
        />
      </div>
    </div>
    <br />
    <div class="container-fluid pr-0 pl-0">
      <div class="align-self-center row m-0" style="width: 100%">
        <div class="p-0 col-12">
          <div class="pr-0 bs-content jumbotron jumbotron-fluid jub">
            <div class="wrapper p-2 rounded">
              <div class="etapas">
                <div class="conteudo">
                  A partir do dia 01 de Março você já poderá contar com o
                  atendimento da Amil.
                </div>
              </div>
            </div>

            <div class="wrapper p-2 rounded" style="margin-top: 0">
              <div class="etapas">
                <div class="conteudo">
                  Agora você pode conceder o plano odontológico também para seus
                  dependentes.
                </div>
              </div>
            </div>

            <div class="wrapper p-2 rounded">
              <div class="etapas">
                <div class="conteudo">
                  Todos os colaboradores receberão o número de carteirinha Amil
                  para iniciar a utilização do novo plano.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
