<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-10">
      <div class="jumbotron">
        <h1 class="display-5">Confirmação de dados</h1>
        <hr class="my-4" />
        <p class="lead">Plano escolhido:</p>
        <h5>{{ plano.nome_plano }}</h5>
      </div>
    </div>
  </div>
  <div class="row justify-content-center" *ngIf="beneficiario">
    <div class="col-10">
      <form
        [formGroup]="form"
        class="row justify-content-center"
        *ngIf="!titular"
      >
        <div class="col-sm-12 col-md-8 form-group">
          <label for="nome">Nome</label>
          <input
            type="text"
            class="form-control"
            id="nome"
            formControlName="nome"
            aria-describedby="nome"
            placeholder="Nome completo"
          />
        </div>
        <div class="col-sm-12 col-md-4 form-group">
          <label for="cpf">CPF</label>
          <input
            type="text"
            class="form-control"
            id="cpf"
            aria-describedby="cpf"
            placeholder="CPF"
            formControlName="cpf"
            #thisElement
            maxlength="14"
          />
        </div>

        <div class="col-sm-12 col-md-6 form-group">
          <label for="rg">RG</label>
          <input
            type="text"
            class="form-control"
            id="rg"
            aria-describedby="rg"
            placeholder="RG"
            formControlName="rg"
          />
        </div>

        <div class="col-sm-12 col-md-6 form-group">
          <label for="expedicao">Data Expedição</label>
          <input
            type="date"
            class="form-control"
            id="expedicao"
            aria-describedby="expedicao"
            formControlName="data_expedicao"
          />
        </div>

        <div class="col-sm-12 col-md-4 form-group">
          <label for="nascimento">Data de nascimento</label>
          <input
            type="date"
            class="form-control"
            id="nascimento"
            aria-describedby="nascimento"
            formControlName="data_nascimento"
          />
        </div>

        <div class="col-sm-12 col-md-4 form-group">
          <label for="rg">Telefone</label>
          <input
            type="text"
            class="form-control"
            id="telefone"
            aria-describedby="telefone"
            placeholder="telefone"
            formControlName="telefone"
          />
        </div>

        <div class="col-sm-12 col-md-4 form-group">
          <label for="sexo">Sexo</label>
          <select class="form-control" id="sexo" formControlName="sexo">
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
          </select>
        </div>

        <div class="col-sm-12 col-md-3 form-group">
          <label for="estadoCivil">Estado Civil</label>
          <select
            class="form-control"
            id="estadoCivil"
            formControlName="estado_civil"
          >
            <option value="Solteiro">Solteiro(a)</option>
            <option value="Casado">Casado(a)</option>
            <option value="Outros">Outros</option>
          </select>
        </div>

        <div class="col-sm-12 col-md-9 form-group">
          <label for="nomeMae">Nome da mãe</label>
          <input
            type="text"
            formControlName="nome_mae"
            class="form-control"
            id="nomeMae"
            aria-describedby="nomeMae"
            placeholder="Nome completo da mãe"
          />
        </div>

        <div class="col-sm-12 col-md-8 form-group">
          <label for="endereco">Endereço</label>
          <input
            type="text"
            formControlName="endereco"
            class="form-control"
            id="endereco"
            aria-describedby="endereco"
            placeholder="Endereço"
          />
        </div>

        <div class="col-sm-6 col-md-3 form-group">
          <label for="cep">CEP</label>
          <input
            type="text"
            formControlName="cep"
            class="form-control"
            id="cep"
            aria-describedby="cep"
            placeholder="CEP"
          />
        </div>

        <div class="col-sm-6 col-md-1 form-group">
          <label for="estado">Estado</label>
          <input
            type="text"
            formControlName="estado"
            class="form-control"
            id="estado"
            aria-describedby="estado"
            placeholder="Estado"
          />
        </div>

        <div class="col-4" style="text-align: center">
          <button (click)="confirmacaoCadastro()" class="btn btn-primary">
            Confirmar dados Titular
          </button>
        </div>
      </form>
      <div class="row justify-content-center" *ngIf="titular">
        <div class="col-4">
          <p class="text-center">
            Clique no botão ao lado para adicionar campo para novo dependente
          </p>
        </div>
        <div class="col-4" style="text-align: center">
          <button (click)="addDependente()" class="btn btn-primary">
            Adicionar dependente
          </button>
        </div>
      </div>
      <ng-container *ngIf="titular">
        <form
          *ngFor="let dependente of formDependentes.controls"
          [formGroup]="dependente"
          class="row justify-content-center"
        >
          <div class="col-12">Cadastro de dependentes</div>
          <div class="col-sm-12 col-md-8 form-group">
            <label for="nome">Nome</label>
            <input
              type="text"
              class="form-control"
              id="nome"
              formControlName="nome"
              aria-describedby="nome"
              placeholder="Nome completo"
            />
          </div>
          <div class="col-sm-12 col-md-4 form-group">
            <label for="cpf">CPF</label>
            <input
              type="text"
              class="form-control"
              id="cpf"
              aria-describedby="cpf"
              placeholder="CPF"
              formControlName="cpf"
              #thisElementDependente
              maxlength="14"
            />
          </div>

          <div class="col-sm-12 col-md-6 form-group">
            <label for="rg">RG</label>
            <input
              type="text"
              class="form-control"
              id="rg"
              formControlName="rg"
              aria-describedby="rg"
              placeholder="RG"
            />
          </div>

          <div class="col-sm-12 col-md-6 form-group">
            <label for="expedicao">Data Expedição</label>
            <input
              type="date"
              class="form-control"
              id="expedicao"
              aria-describedby="expedicao"
              formControlName="data_expedicao"
            />
          </div>

          <div class="col-sm-12 col-md-4 form-group">
            <label for="nascimento">Data de nascimento</label>
            <input
              type="date"
              class="form-control"
              id="nascimento"
              aria-describedby="nascimento"
              formControlName="data_nascimento"
              #data_nascimento
            />
          </div>

          <div class="col-sm-12 col-md-4 form-group">
            <label for="rg">Grau de parentesco</label>
            <input
              type="text"
              class="form-control"
              id="grau"
              aria-describedby="grau"
              placeholder="grau"
              formControlName="grau"
            />
          </div>
          <div class="col-sm-12 col-md-4 form-group">
            <label for="rg">Telefone</label>
            <input
              type="text"
              class="form-control"
              id="telefone"
              aria-describedby="telefone"
              placeholder="telefone"
              formControlName="telefone"
            />
          </div>

          <div class="col-sm-12 col-md-3 form-group">
            <label for="sexo">Sexo</label>
            <select class="form-control" id="sexo" formControlName="sexo">
              <option value="m">Masculino</option>
              <option value="f">Feminino</option>
            </select>
          </div>

          <div class="col-sm-12 col-md-3 form-group">
            <label for="estadoCivil">Estado Civil</label>
            <select
              class="form-control"
              id="estadoCivil"
              formControlName="estado_civil"
            >
              <option value="solteiro(a)">Solteiro(a)</option>
              <option value="casado(a)">Casado(a)</option>
              <option value="outros">Outros</option>
            </select>
          </div>

          <div class="col-sm-12 col-md-6 form-group">
            <label for="nomeMae">Nome da mãe</label>
            <input
              type="text"
              class="form-control"
              id="nomeMae"
              aria-describedby="nomeMae"
              placeholder="Nome completo da mãe"
              formControlName="nome_mae"
            />
          </div>
        </form>
      </ng-container>
    </div>
  </div>
  <div class="row justify-content-center" *ngIf="titular">
    <div class="col-2">
      <button
        type="button"
        class="btn btn-primary"
        (click)="enviarDados()"
        data-dismiss="modal"
      >
        Confirmar escolha!
      </button>
    </div>
  </div>
</div>
