<div class="container">
  <div class="container p-1 mt-4 w-100">
    <div class="desk">
      <div class="w-25 title text-center">
        <h5>
          <p class="font-weigth-bold">
            REDE
            <a
              class="text-light font-italic"
              style="background-color: var(--primary-color)"
              >CREDENCIADA</a
            >
          </p>
        </h5>
      </div>
      <div class="my-5">
        <img src="./assets/banner/paginas/rede.png" class="w-100" />
      </div>
    </div>
    <div class="mobile">
      <div class="title text-center">
        <h5>
          <p class="font-weigth-bold">
            REDE
            <a
              class="text-light font-italic"
              style="background-color: var(--primary-color)"
              >CREDENCIADA</a
            >
          </p>
        </h5>
      </div>
      <div class="my-5">
        <img src="./assets/banner/paginas/mobile/rede.png" class="w-100" />
      </div>
    </div>

    <div class="container text-center">
      <p class="text-center font-weight-bold">
        Consulte os dentistas e clínicas credenciadas a Amil Dental.
      </p>
    </div>
    <br />
    <div class="container border">
      <div class="text-center m-auto">
        <div class="row">
          <div class="form-group col-sm-3" *ngIf="especialidades">
            <label for="sel-1">Especialidade</label>
            <select
              class="form-control"
              id="sel1"
              (change)="getUfs(element.value)"
              #element
            >
              <option>-- Especialidade --</option>
              <ng-container *ngFor="let especialidade of especialidades">
                <option [value]="">{{ especialidade.especialidade }}</option>
              </ng-container>
            </select>
          </div>

          <div class="form-group col-sm-3" *ngIf="ufs && ufs.length > 0">
            <label for="sel-1">UF</label>
            <select
              class="form-control"
              id="sel1"
              (change)="getCidades(element.value)"
              #element
            >
              <option>-- Estado --</option>
              <ng-container *ngFor="let uf of ufs">
                <option [value]="uf.uf">{{ uf.uf }}</option>
              </ng-container>
            </select>
          </div>

          <div class="form-group col-sm-3" *ngIf="cidades">
            <label for="sel-1">Cidade</label>
            <select
              value="false"
              class="form-control"
              id="sel1"
              (change)="getBairros(element.value)"
              #element
            >
              <option>-- Cidade --</option>
              <ng-container *ngFor="let cidade of cidades">
                <option [value]="cidade.cidade">
                  {{ cidade.cidade }}
                </option>
              </ng-container>
            </select>
          </div>

          <div class="form-group col-sm-3" *ngIf="bairros">
            <label for="sel-1">Bairros</label>
            <select
              class="form-control"
              id="sel1"
              (change)="getRedeCredenciada(element.value)"
              #element
            >
              <option>-- Bairro --</option>
              <ng-container *ngFor="let bairro of bairros">
                <option [value]="bairro.bairro">
                  {{ bairro.bairro }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>

        <div class="row" *ngIf="reqRede">
          <div class="col">
            <p>Nenhuma rede credenciada encontrada na localização informada.</p>
            <p><b>Favor selecionar outra localidade</b></p>
          </div>
        </div>

        <div class="row" *ngIf="redecredenciada">
          <ng-container
            *ngFor="let rede of redecredenciada.slice(offset, limit)"
          >
            <div class="col-12 col-sm-6 col-xl-4 card">
              <div class="card-body">
                <h5 class="card-title">{{ rede.nome }}</h5>
                <p class="card-text">{{ rede.cidade }} - {{ rede["UF"] }}</p>
                <p class="card-text">{{ rede.bairro }}</p>
                <p class="card-text">{{ rede.especialidade }}</p>
                <p class="card-text">{{ rede.telefone }}</p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="btns">
        <button
          type="button"
          class="btn btn-outline-secondary btn-lg"
          (click)="pagination(-1)"
          [disabled]="!offset"
        >
          Anterior
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary btn-lg"
          (click)="pagination(1)"
          [disabled]="redecredenciada && limit >= redecredenciada.length"
        >
          Proximo
        </button>
      </div>

      <div class="container border rounded espacamento">
        <div class="container">
          <div class="row">
            <h4 style="color: #601848" class="m-auto">
              Dicas Célebre
              <img
                src="./assets/icon/celebre/bandeira.png"
                style="max-width: 20px"
              />
            </h4>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 text-center" style="margin-bottom: 20px">
            <div class="card" style="width: 100%; height: 100%">
              <div class="card-header text-light bg-dark">
                O que é rede credenciada?
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  Rede de atendimento composta por dentistas e clínicas, que são
                  pagos pela Amil.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="container m-auto">
        <div class="row ml-1">
          <div
            class="col-12 col-sm-6 jumbotron border-right border-light"
            style="background-color: var(--primary-color)"
          >
            <div class="row">
              <div class="col-12 text-light">
                Se você tem dúvidas da situação de credenciamento do prestador,
                entre em contato com o estabelecimento ou com a Amil através do
                telefone 3004-1000
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-6 jumbotron border-left border-light"
            style="background-color: var(--primary-color)"
          >
            <div class="col-12 text-light">
              Se o seu dentista não é credenciado na Amil, procure outros
              estabelecimentos que ofereçam o mesmo serviço, procure por uma
              indicação de seus colegas ou entre em contato com a sua operadora.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
