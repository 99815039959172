import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { PopupService } from 'src/app/services/popup.service';
import { PlanoService } from '../../services/plano.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-escolherplano',
  templateUrl: './escolherplano.component.html',
  styleUrls: ['./escolherplano.component.css']
})
export class EscolherplanoComponent implements OnInit {

  plano: any;
  form: FormGroup;
  titular = false;
  beneficiario: any;

  constructor(
    private planoService: PlanoService,
    private fb: FormBuilder,
    private popupService: PopupService,
    private router: Router
  ) {
    this.plano = this.planoService.plano.getValue();
    if (!this.plano) {
      this.router.navigate(['/escolha']);
    }

  }

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    let usuario;
    this.planoService.getBeneficiario().subscribe(
      res => {
        if(res.plano) {
          this.router.navigate(['/adesao']);
        }
        this.initForm(res);
        this.beneficiario = res;
      }
    );
  }

  confirmacaoCadastro() {
    if (!this.form.valid) {
      this.popupService.show('Ainda existem campos obrigatórios não preenchidos', 'danger', 6000)
      return;
    }
    this.titular = true;
    this.planoService.beneficiario.next(this.form.value);
  }

  get formDependentes () { return this.form.controls.dependentes as FormArray;};

  get objectBene() {

    return {
      nome: ['', Validators.required],
      grau: ['', Validators.required],
      rg: ['', Validators.required],
      data_expedicao: ['', Validators.required],
      cpf: [''],
      cpf_titular: [this.beneficiario.cpf, Validators.required],
      sexo: ['', Validators.required],
      data_nascimento: ['', Validators.required],
      telefone: [''],
      nome_mae: ['', Validators.required],
      estado_civil: ['', Validators.required],
      endereco: [''],
      estado: [''],
      cep: [''],
      id_empresa: ['']
    };
  }


  addDependente() {
    let formGroup = this.fb.group(this.objectBene);
    this.formDependentes.push(formGroup);
  }


  initForm(usuario: any) {
    this.form = this.fb.group(
      {
        id: [usuario.id ? usuario.id : '', Validators.required ],
        nome: [usuario.nome ? usuario.nome : '', Validators.required ],
        rg: [usuario.rg ? usuario.rg : ''],
        data_expedicao: [usuario.data_expedicao ? usuario.data_expedicao : '', Validators.required ],
        cpf: [usuario.cpf ? usuario.cpf : ''],
        cpf_titular: [usuario.cpf ? usuario.cpf : '', Validators.required ],
        centro_custo: [usuario.centro_custo ? usuario.centro_custo : ''],
        cargo: [usuario.cargo ? usuario.cargo : '',],
        admissao: [usuario.admissao ? usuario.admissao : ''],
        sexo: [usuario.sexo ? usuario.sexo : '', Validators.required ],
        data_nascimento: [usuario.data_nascimento ? usuario.data_nascimento : '', Validators.required ],
        telefone: [usuario.telefone? usuario.telefone : '', Validators.required ],
        nome_mae: [usuario.nome_mae ? usuario.nome_mae : '', Validators.required ],
        endereco: [usuario.endereco ? usuario.endereco : ''],
        estado_civil: [usuario.estado_civil ? usuario.estado_civil : '', Validators.required ],
        estado: [usuario.estado ? usuario.estado : '' ],
        cep: [usuario.cep ? usuario.cep : ''],
        id_empresa: [usuario.id_empresa ? usuario.id_empresa : '', Validators.required ],
        dependentes: new FormArray([])
      }
    );
    usuario.dependentes.forEach(dependente => {
      let formGroup = this.fb.group(dependente);
      this.formDependentes.push(formGroup);
    });
  }

  enviarDados() {
    this.planoService.cadastraPlanoDependentes({ nome_plano: this.plano.nome_plano, descricao: 'Plano dental.', valor: this.plano.valor, ...this.form.value}).subscribe(
      response => {
        this.router.navigate(['adesao']);
      },
      error => {
        console.log(error.error);

        this.popupService.show(error.error, 'danger', 6000)
      }
    )

  }
}
