<div class="container" *ngIf="beneficiario">
  <div class="row mt-5 justify-content-center">
    <div class="col-sm-6">
      <ul class="list-group">
        <li class="list-group-item active">
          <b> {{ beneficiario.nome }}</b>
          , confira o resumo da sua escolha
        </li>
        <li class="list-group-item">
          Plano: <b>{{ beneficiario.plano }}</b>
        </li>
        <li class="list-group-item">
          Acomodação:
          <b class="capitalize">
            {{ beneficiario.acomodacao }}
          </b>
        </li>
        <li class="list-group-item" *ngIf="beneficiario.valor_titular">
          Valor total da sua mensalidade:
          <b>R${{ beneficiario.valor_titular }}</b>
        </li>
        <li class="list-group-item" *ngIf="beneficiario.data_criacao">
          Data da escolha:
          <b>{{ beneficiario.data_criacao | date: "d/M/yyyy, H:mm" }}</b>
        </li>
        <!-- <li class="list-goup-item" *ngIf="beneficiario.carteirinha">
          Número da Carteirinha:
          <b>{{ beneficiario.carteirinha }}</b>
        </li> -->
      </ul>

      <ul class="list-group mt-5">
        <li class="list-group-item active">
          <b>
            O VALOR MENSAL REFERENTE AS MENSALIDADES DA SUA FAMÍLIA, SERÁ:
          </b>
        </li>

        <li class="list-group-item">
          SOMA DOS VALORES R${{ beneficiario.valor_total }}
        </li>
        <li class="list-group-item text-center strong">
          Seu plano estará vigente a partir de <mark>01/03/2021</mark>.
          <br />
          O desconto será realizado na Folha de Pagamento.
        </li>
      </ul>
    </div>

    <div class="col-sm-6">
      <ul class="list-group">
        <li class="list-group-item active">
          <b> DEPENDENTES </b>
        </li>
        <ng-container *ngFor="let dependente of beneficiario.dependentes">
          <li class="list-group-item">
            <b>{{ dependente.nome }}</b>
            <br />
            R${{ dependente.valor_plano }}
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
