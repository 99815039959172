import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AplicativoComponent } from './components/aplicativo/aplicativo.component';
import { BeneficiosComponent } from './components/beneficios/beneficios.component';
import { CarteirinhaComponent } from './components/carteirinha/carteirinha.component';
import { DocumentosComponent } from './components/documentos/documentos.component';
import { EtapasProjetoComponent } from './components/etapas-projeto/etapas-projeto.component';
import { FaleConoscoComponent } from './components/fale-conosco/fale-conosco.component';
import { PassoComponent } from './components/passo/passo.component';
import { PerguntasComponent } from './components/perguntas/perguntas.component';
import { RedeCredenciadaComponent } from './components/rede-credenciada/rede-credenciada.component';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { environment } from '../environments/environment';
import { ApiUrlInterceptor, API_URL } from './interceptors/http.interceptor';
import { CarouselComponent } from './components/carousel/carousel.component';
import { FooterComponent } from './container/footer/footer.component';
import { NavbarComponent } from './container/navbar/navbar.component';
import { HeaderComponent } from './container/header/header.component';
import { PopupComponent } from './container/popup/popup.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EscolhaComponent } from './components/escolha/escolha.component';
import { EscolherplanoComponent } from './components/escolherplano/escolherplano.component';
import { AdesaoComponent } from './components/adesao/adesao.component';

@NgModule({
  declarations: [
    AppComponent,
    AplicativoComponent,
    BeneficiosComponent,
    CarteirinhaComponent,
    DocumentosComponent,
    EtapasProjetoComponent,
    FaleConoscoComponent,
    PassoComponent,
    PerguntasComponent,
    RedeCredenciadaComponent,
    LoginComponent,
    CarouselComponent,
    FooterComponent,
    NavbarComponent,
    HeaderComponent,
    PopupComponent,
    EscolhaComponent,
    EscolherplanoComponent,
    AdesaoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true, deps: [API_URL] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
