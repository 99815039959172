import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  loadingLogin: boolean = false;

  permissao: boolean;
  user: any;
  form: FormGroup;
  public returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private popupService: PopupService
  ) {
    if (this.auth.currentUserValue.auth) {
      this.router.navigate(['/escolha']);
    }
  }

  ngOnInit(): void {
    this.returnUrl = '/redirect';
    this.form = this.formBuilder.group({
      cpf: ['', Validators.required]
    });

  }

  get fLogin() { return this.form.controls; }


  mCPF(form: string) {
    let cpf: string
    cpf = this.fLogin.cpf.value;
    cpf = cpf.replace(/\D/g, "")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    this.fLogin.cpf.setValue(cpf);
    return true
  }

  onSubmit() {
    // this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.loadingLogin = true;

    this.user = this.auth.login(this.fLogin.cpf.value).pipe(first()).subscribe(
      data => {
        this.loadingLogin = false;
        this.router.navigate(['/adesao']);
      },
      error => {
        console.error(error);
        this.popupService.show(error.error, 'danger');
        this.loadingLogin = false;
      });
  }

}
