import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrentUserData } from '../model/current.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<CurrentUserData>;

  public currentUser: Observable<CurrentUserData>;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem(`currentUser`)));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue() {
    return this.currentUserSubject?.getValue() ? this.currentUserSubject.getValue() : { auth: false, token: false, funcionario: {} };
  }

  public get autorizado() {
    return this.currentUser;
  }

  public temPermissao() {
    const found = this.currentUserValue.auth
    if (found == undefined || !found) {
      return false
    } else {
      return true
    }
  }

  login(cpf: String) {
    let username: string = 'username';
    let password: string = 'password';
    let headers: Headers = new Headers();
    const headerDict = {
      "Authorization": "Basic " + btoa(username + ":" + password),
      'Content-Type': 'application/json'
    };
    let options = { headers: new HttpHeaders(headerDict) };
    return this.http.post<CurrentUserData>('torradental/login', { cpf }, options)
      .pipe(map(
        user => {
        localStorage.setItem('currentUser', JSON.stringify(user));

        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(undefined);
    this.router.navigate(['/']);
  }

  getCarteirinhas(cpf){
    return this.http.get<any>('torra/usuario/carteirinhas/'+cpf );
  }

}
