import { Component, OnInit } from "@angular/core";
import { PlanoService } from "../../services/plano.service";
import { Router } from "@angular/router";
import {
  IbodyDependente,
  IbeneficiarioRequest,
} from "../../model/beneficiario.model";

@Component({
  selector: "app-carteirinha",
  templateUrl: "./carteirinha.component.html",
  styleUrls: ["./carteirinha.component.css"],
})
export class CarteirinhaComponent implements OnInit {
  beneficiario: IbeneficiarioRequest;

  autorizado: boolean = false;
  titular: any;
  dependentes: any[];
  beneficiarios: any[];
  plano: string;
  grupo: any;

  constructor(private planoService: PlanoService, private router: Router) {}

  ngOnInit(): void {
    this.getBeneficiario();
  }

  getBeneficiario() {
    this.planoService.getBeneficiario().subscribe((res) => {
      if (!res.plano) {
        this.router.navigate(["/"]);
        return;
      }
      this.beneficiario = res;
    });
  }
}
