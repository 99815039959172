import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  private messageSubject: BehaviorSubject<any>;
  public message: Observable<any>;
  private typeSubject: BehaviorSubject<any>;
  public type: Observable<any>;

  constructor() {
    this.messageSubject = new BehaviorSubject<any>(null);
    this.message = this.messageSubject.asObservable();
    this.typeSubject = new BehaviorSubject<any>(null);
    this.type = this.typeSubject.asObservable();
   }

   /*

    Types: danger e success

   */

  show(message: string, type: 'danger' | 'success', time: number = 10000){
    this.typeSubject.next(type)
    this.messageSubject.next(message)
    setTimeout(() => {
      this.hide()
    },time)
  }

  hide(){
    this.messageSubject.next(null);
    this.typeSubject.next(null);
  }
}
