import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  autorizado: boolean;
  public grupo: boolean = true;

  constructor(
    private router: Router,
    private auth: AuthService
  ) {
    this.auth.currentUser.subscribe(
      x => {
        this.autorizado = this.auth.currentUserValue.auth;
      }

    );
  }

  logar() {
    this.router.navigate(['/login']);
  }

  logout() {
    this.auth.logout();
  }

}
