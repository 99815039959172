<ng-container>
  <div class="container" style="margin-bottom: 150px">
    <div class="desk">
      <div class="w-25 titleBanner text-center">
        <h5 class="row">
          <p class="col-9 font-weigth-bold">
            Dúvidas
            <a
              class="text-light font-italic"
              style="background-color: var(--primary-color)"
              >Frequentes</a
            >
          </p>
        </h5>
      </div>
      <div class="w-100 my-5">
        <img
          src="./assets/banner/paginas/perguntas.png"
          alt="Reembolso"
          class="w-100"
        />
      </div>
    </div>
    <div class="mobile">
      <div class="titleBanner text-center">
        <h5 class="row">
          <p class="col-9 font-weigth-bold">
            Dúvidas
            <a
              class="text-light font-italic"
              style="background-color: var(--primary-color)"
              >Frequentes</a
            >
          </p>
        </h5>
      </div>
      <div class="w-100 my-5">
        <img
          src="./assets/banner/paginas/mobile/perguntas.png"
          alt="Reembolso"
          class="w-100"
        />
      </div>
    </div>
    <!--
    <div class="row">
      <h2 class="col-12" style="color: #1e1f47">Sobre Amil</h2>

      <div class="col-12">
        Uma empresa do Grupo Unitedhealth, a Amil é a maior operadora de saúde
        privada do país e é considerada a mais sólida, valiosa e inovadora do
        mercado de saúde.
      </div>
    </div> -->
    <div class="row">
      <h2 class="col-12 mt-5" style="color: #1e1f47">Sobre Amil</h2>

      <p class="col-12">
        Uma empresa do Grupo Unitedhealth, a Amil é a maior operadora de saúde
        privada do país e é considerada a mais sólida, valiosa e inovadora do
        mercado de saúde.
      </p>
    </div>

    <div class="row">
      <h2 class="col-12 mt-5" style="color: #1e1f47">Carteirinha Amil</h2>

      <p class="col-12">
        É o documento mais importante do cliente Amil, que contém todas as
        informações necessárias para o atendimento, dentre elas, a sequência
        numérica, abaixo do nome, que o identifica como cliente Amil. A
        carteirinha é de uso exclusivo do segurado e não deve ser emprestada a
        terceiros.
      </p>
    </div>

    <div class="row pb-5 my-5">
      <h2 class="col-12 text-center my-5" style="color: #1e1f47">
        Sobre o plano
      </h2>

      <div id="accordion" class="col-12">
        <div class="card">
          <div class="card-header" id="heading1">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#collapse1"
                aria-expanded="false"
                aria-controls="collapse1"
              >
                A partir de qual data poderei utilizar o Plano Odontológico?
              </button>
            </h5>
          </div>
          <div
            id="collapse1"
            class="collapse"
            aria-labelledby="heading1"
            data-parent="#accordion"
          >
            <div class="card-body">
              A partir da vigência do contrato da empresa, 01/03/2021
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="heading2">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#collapse2"
                aria-expanded="false"
                aria-controls="collapse2"
              >
                Posso incluir dependentes e agregados sem carência a qualquer
                momento?
              </button>
            </h5>
          </div>
          <div
            id="collapse2"
            class="collapse"
            aria-labelledby="heading2"
            data-parent="#accordion"
          >
            <div class="card-body">
              Não. Todas as inclusões deverão seguir a regra contratual acordada
              entre a empresa e a Amil. Os dependentes e os agregados ficarão
              isentos de cumprir carência somente na adesão do contrato, ou
              dentro dos 30 dias após o nascimento, casamento ou adoção.
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="heading3">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#collapse3"
                aria-expanded="false"
                aria-controls="collapse3"
              >
                O dependente e o agregado poderão ter plano diferente do
                titular?
              </button>
            </h5>
          </div>
          <div
            id="collapse3"
            class="collapse"
            aria-labelledby="heading3"
            data-parent="#accordion"
          >
            <div class="card-body">
              Não, obrigatoriamente o dependente e o agregado carregarão o mesmo
              plano do titular.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
