<div class="container">
  <div class="desk">
    <div class="w-25 title text-center">
      <h5>
        <p
          class="font-weigth-bold text-light"
          style="background-color: var(--primary-color)"
        >
          Benefício
        </p>
      </h5>
    </div>
    <div class="my-5">
      <img class="w-100" src="./assets/banner/paginas/beneficios.png" alt="" />
    </div>
  </div>
  <div class="mobile">
    <div class="title text-center">
      <h5>
        <p
          class="font-weigth-bold text-light"
          style="background-color: var(--primary-color)"
        >
          Benefício
        </p>
      </h5>
    </div>
    <div class="my-5">
      <img
        class="w-100"
        src="./assets/banner/paginas/mobile/beneficios.png"
        alt=""
      />
    </div>
  </div>

  <div class="text-center">
    <h3>Benefícios da Amil</h3>
    <p>Reembolso, Descontos, Atendimento 24h e etc...</p>
  </div>

  <div class="row d-flex justify-content-center">
    <ng-container *ngFor="let item of beneficios">
      <div
        class="col-md-4 col-sm-6 text-center d-flex"
        *ngIf="!(item.titulo == 'Amil Reembolso')"
      >
        <div class="m-1 p-3 my-4 bg-light">
          <img
            class="align-self-center"
            style="height: 75px; width: auto"
            [src]="item.imagePath"
            alt="Card image cap"
          />
          <div class="mt-3">
            <h5 class="amil-color">{{ item.titulo }}</h5>
            <p>{{ item.texto }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    class="row d-flex justify-content-center rounded p-5 text-white my-5"
    style="background-color: var(--primary-color)"
  >
    <h6 class="m-0 text-center">
      UMA EMPRESA DO GRUPO UNITEDHEALTH, A AMIL É A MAIOR OPERADORA DE SAÚDE
      PRIVADA DO PAÍS E É CONSIDERADA A MAIS SÓLIDA, VALIOSA E INOVADORA DO
      MERCADO DE SAÚDE.
    </h6>
  </div>

  <div class="text-center mb-5">
    <h3>Amil em números</h3>
  </div>

  <div class="row d-flex justify-content-center">
    <div
      class="col-md-3 col-sm-6 text-center"
      *ngFor="let item of amilEmNumeros"
    >
      <div class="m-2 mb-5">
        <img
          class="align-self-center"
          style="height: 75px; width: auto"
          [src]="item.imagePath"
          alt="Card image cap"
        />
        <div class="mt-3">
          <h5 class="amil-color">{{ item.titulo }}</h5>
          <p>{{ item.texto }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
