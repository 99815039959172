import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-passo',
  templateUrl: './passo.component.html',
  styleUrls: ['./passo.component.css']
})
export class PassoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
