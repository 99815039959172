<div class="desk py-2 sticky-top bg-dark shadow">
  <nav class="navbar navbar-expand-sm navbar-dark justify-content-center">
    <div class="d-flex w-100 mw-1140">
      <ul class="navbar-nav w-100 mr-4 d-flex justify-content-between">
        <li class="nav-item">
          <u class="h6"
            ><a class="nav-link" routerLink="etapas">Etapas do Projeto</a></u
          >
        </li>

        <li class="nav-item">
          <u class="h6"
            ><a class="nav-link" routerLink="carteirinha">Carteirinha</a></u
          >
        </li>

        <li class="nav-item">
          <u class="h6"
            ><a class="nav-link" routerLink="escolha">Plano / Dependente</a></u
          >
        </li>

        <li class="nav-item">
          <!-- <u class="h6"><a class="nav-link" routerLink="amil">Amil</a></u> -->

          <div class="dropdown">
            <button
              class="btn btn-link dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <u class="h6">
                <a class="nav-link d-inline">AMIL</a>
              </u>
            </button>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" routerLink="rede">Rede Credenciada</a>
              <a class="dropdown-item" routerLink="beneficio">Benefícios</a>
              <!-- <a class="dropdown-item" routerLink="comparativo">Comparativo</a> -->
              <a class="dropdown-item" routerLink="aplicativo">Aplicativo</a>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <u class="h6"
            ><a class="nav-link" routerLink="perguntas"
              >Perguntas Frequentes</a
            ></u
          >
        </li>
        <li class="nav-item">
          <u class="h6"
            ><a class="nav-link" routerLink="faleConosco">Fale Conosco</a></u
          >
        </li>
      </ul>

      <ng-container *ngIf="!autorizado; else sair">
        <button
          class="btn font-weight-bold"
          type="button"
          (click)="logar()"
          style="
            max-width: 100%;
            background-color: var(--primary-color);
            color: white;
          "
        >
          Entrar
        </button>
      </ng-container>

      <ng-template #sair>
        <button class="btn btn-primary ml-1" type="button" (click)="logout()">
          Sair
        </button>
      </ng-template>
    </div>
  </nav>
</div>

<ng-container *ngIf="!autorizado">
  <div class="collapse" id="login">
    <div class="card card-body">
      <app-login></app-login>
    </div>
  </div>
</ng-container>

<div class="mob">
  <nav class="navbar navbar-expand-md bg-dark navbar-dark">
    <u class="minMob h7"><a class="navbar-brand" routerLink="">Menu</a></u>

    <button
      class="navbar-toggler"
      data-toggle="collapse"
      data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="navbar-collapse collapse"
      id="navbarNavAltMarkup"
      #navbarNavAltMarkup
    >
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="etapas">Etapas do Projeto</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" routerLink="carteirinha">Carteirinha</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" routerLink="escolha">Plano / Dependente</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#"
            >Amil</a
          >
          <div class="dropdown-menu">
            <a class="dropdown-item" routerLink="rede">Rede Credenciada</a>
            <a class="dropdown-item" routerLink="beneficio">Benefícios</a>
            <!-- <a class="dropdown-item" routerLink="comparativo">Comparativo</a> -->
            <a class="dropdown-item" routerLink="aplicativo">Aplicativo</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-item nav-link" routerLink="perguntas"
            >Perguntas Frequentes</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-item nav-link" routerLink="faleConosco">Fale conosco</a>
        </li>

        <ng-container *ngIf="!autorizado; else sair">
          <li class="text-center">
            <button
              class="btn btn-primary ml-3"
              type="button"
              (click)="logar()"
            >
              Entrar
            </button>
          </li>
        </ng-container>

        <ng-template #sair>
          <li class="text-center">
            <button
              class="btn btn-primary ml-3"
              type="button"
              (click)="logout()"
            >
              Sair
            </button>
          </li>
        </ng-template>
      </ul>
    </div>
  </nav>
</div>
