import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aplicativo',
  templateUrl: './aplicativo.component.html',
  styleUrls: ['./aplicativo.component.css']
})
export class AplicativoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
