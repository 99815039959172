<div class="desk">
  <div id="demo" class="carousel slide" data-ride="carousel">
    <ul class="carousel-indicators">
      <li data-target="#demo" data-slide-to="0" class="active"></li>
      <li data-target="#demo" data-slide-to="1"></li>
      <li data-target="#demo" data-slide-to="2"></li>
      <li data-target="#demo" data-slide-to="3"></li>
      <li data-target="#demo" data-slide-to="4"></li>
    </ul>
    <div class="carousel-inner text-center">
      <div class="carousel-item active">
        <a [routerLink]="['/etapas']" routerLinkActive="router-link-active">
          <img src="./assets/banner/ban0.png" alt="banApp" width="1200" />
        </a>
        <div class="carousel-caption">
          <div class="containerText">
            <img src="./assets/logo/torra.png" class="logoSpdm" />
            <p>
              A partir do dia 01 de Março as lojas
              <b style="color: var(--primary-color)">TORRA</b> irão contar com o
              plano odonto Amil!
            </p>
            <button
              [routerLink]="['/etapas']"
              routerLinkActive="router-link-active"
              class="btn"
              style="background-color: var(--primary-color); color: white"
            >
              Saiba mais
            </button>
            <div class="containerText2"></div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <a [routerLink]="['/aplicativo']" routerLinkActive="router-link-active"
          ><img src="./assets/banner/ban1.png" alt="banCop" width="1200"
        /></a>
        <div class="carousel-caption">
          <div class="containerText">
            <div class="textApp">
              <h4>
                <p>
                  Você conhece o <br /><b style="color: var(--primary-color)"
                    >aplicativo da Amil Odonto?</b
                  >
                </p>
              </h4>
              <button
                [routerLink]="['/aplicativo']"
                routerLinkActive="router-link-active"
                class="btn"
                style="background-color: var(--primary-color); color: white"
              >
                Saiba mais
              </button>
            </div>
            <div class="containerText2"></div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <a [routerLink]="['/etapas']" routerLinkActive="router-link-active"
          ><img src="./assets/banner/ban2.png" alt="banMudanca" width="1200"
        /></a>
        <div class="carousel-caption">
          <div class="containerText">
            <div class="textApp">
              <h4>
                <p>Saiba quais são <br /><b>as mudanças</b></p>
              </h4>
              <button
                [routerLink]="['/etapas']"
                routerLinkActive="router-link-active"
                class="btn"
                style="background-color: var(--primary-color); color: white"
              >
                Conferir
              </button>
            </div>
            <div class="containerText2"></div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <a [routerLink]="['/beneficio']" routerLinkActive="router-link-active"
          ><img src="./assets/banner/ban3.png" alt="banBeneficio" width="1200"
        /></a>
        <div class="carousel-caption">
          <div class="containerText">
            <div class="textApp">
              <h4>
                <p>Confira <br /><b>os Benefícios</b></p>
              </h4>
              <button
                [routerLink]="['/beneficio']"
                routerLinkActive="router-link-active"
                class="btn"
                style="background-color: var(--primary-color); color: white"
              >
                Conferir
              </button>
            </div>
            <div class="containerText2"></div>
          </div>
        </div>
      </div>
      <!-- <div class="carousel-item">
        <a [routerLink]="['/reembolso']" routerLinkActive="router-link-active"
          ><img src="./assets/banner/ban4.png" alt="banReembolso" width="1200"
        /></a>
        <div class="carousel-caption">
          <div class="containerText">
            <div class="textApp">
              <h4>
                <p>Quais são as minhas opções de <br /><b>Reembolso?</b></p>
              </h4>
              <button
                [routerLink]="['/reembolso']"
                routerLinkActive="router-link-active"
                class="btn"
                style="background-color: var(--primary-color); color: white"
              >
                Conferir
              </button>
            </div>
            <div class="containerText2"></div>
          </div>
        </div>
      </div> -->
    </div>
    <a class="carousel-control-prev" href="#demo" data-slide="prev">
      <span class="carousel-control-prev-icon"></span>
    </a>
    <a class="carousel-control-next" href="#demo" data-slide="next">
      <span class="carousel-control-next-icon"></span>
    </a>
  </div>
</div>

<div class="bannerMob">
  <div id="demoMob" class="carousel slide" data-ride="carousel">
    <ul class="carousel-indicators">
      <li data-target="#demoMob" data-slide-to="0" class="active"></li>
      <li data-target="#demoMob" data-slide-to="1"></li>
      <li data-target="#demoMob" data-slide-to="2"></li>
      <li data-target="#demoMob" data-slide-to="3"></li>
      <li data-target="#demoMob" data-slide-to="4"></li>
    </ul>
    <div class="carousel-inner text-center">
      <div class="carousel-item active">
        <a [routerLink]="['/etapas']" routerLinkActive="router-link-active"
          ><img src="./assets/banner/mob/mob0.png" alt="banApp"
        /></a>
        <div class="carousel-caption">
          <div
            class="containerText"
            style="background-color: var(--blue-color)"
          >
            <!-- <img src="./assets/torralogobranco.png" class="logoTorra" /> -->
            <img src="./assets/logo/torra.png" class="logoTorra" />
            <p>
              A partir do dia 01 de Março as lojas
              <b style="color: var(--primary-color)">Torra</b> irão contar com o
              plano Dental da Amil!
            </p>
            <button
              [routerLink]="['/etapas']"
              routerLinkActive="router-link-active"
              class="btn"
              style="background-color: var(--primary-color); color: white"
            >
              Saiba mais
            </button>
            <!-- <div class="containerText2">
                        </div> -->
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <a [routerLink]="['/aplicativo']" routerLinkActive="router-link-active"
          ><img src="./assets/banner/mob/mobapp1.png" alt="banCop"
        /></a>
        <div class="carousel-caption">
          <div
            class="containerText"
            style="background-color: var(--blue-color)"
          >
            <h4>
              <p>
                Você conhece o <br /><b style="color: var(--primary-color)"
                  >aplicativo da Amil?</b
                >
              </p>
            </h4>
            <button
              [routerLink]="['/aplicativo']"
              routerLinkActive="router-link-active"
              class="btn"
              style="background-color: var(--primary-color); color: white"
            >
              Saiba mais
            </button>
            <!-- <div class="containerText2">
                        </div> -->
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <a [routerLink]="['/etapas']" routerLinkActive="router-link-active"
          ><img src="./assets/banner/mob/mob1.png" alt="banMudanca"
        /></a>
        <div class="carousel-caption">
          <div
            class="containerText"
            style="background-color: var(--blue-color)"
          >
            <h4>
              <p>Saiba quais são <br /><b>as mudanças</b></p>
            </h4>
            <button
              [routerLink]="['/etapas']"
              routerLinkActive="router-link-active"
              class="btn"
              style="background-color: var(--primary-color); color: white"
            >
              Conferir
            </button>
            <!-- <div class="containerText2">
                    </div> -->
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <a [routerLink]="['/beneficio']" routerLinkActive="router-link-active"
          ><img src="./assets/banner/mob/mob2.png" alt="banBeneficio"
        /></a>
        <div class="carousel-caption">
          <div
            class="containerText"
            style="background-color: var(--blue-color)"
          >
            <h4>
              <p>Confira <br /><b>os Benefícios</b></p>
            </h4>
            <button
              [routerLink]="['/beneficio']"
              routerLinkActive="router-link-active"
              class="btn"
              style="background-color: var(--primary-color); color: white"
            >
              Conferir
            </button>
            <!-- <div class="containerText2">
                    </div> -->
          </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#demoMob" data-slide="prev">
      <span class="carousel-control-prev-icon"></span>
    </a>
    <a class="carousel-control-next" href="#demoMob" data-slide="next">
      <span class="carousel-control-next-icon"></span>
    </a>
  </div>
</div>

<div class="container" style="margin-top: 20px">
  <div class="row">
    <div class="col-12">
      <div class="jumbotron">
        Com mais de 20 anos de experiência no mercado de saúde suplementar,
        vamos além da comercialização de seguros e convênio médico. A Célebre
        possui uma estrutura completa incluindo profissionais multidisciplinares
        que atuam com inteligência e análise de dados de cada beneficiário. Mais
        do que uma corretora, somos especialistas em Gestão de Saúde.
      </div>
    </div>
  </div>
</div>
