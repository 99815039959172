<link
  rel="stylesheet"
  href="https://use.fontawesome.com/releases/v5.6.1/css/all.css"
  integrity="sha384-gfdkjb5BdAXd+lj+gudLWI+BXq4IuLW5IT+brZEZsLFm++aCMlF1V92rMkPaX4PP"
  crossorigin="anonymous"
/>

<app-popup></app-popup>

<app-header></app-header>
<app-navbar></app-navbar>

<div class="mb-5">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
